<template>
  <div>
    <title>SIM INVENTORY ~ PRODUCT LIST DATA PROCESS DETAILS</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Product <b style="color:red">{{product}} </b> => Part <b style="color:red">{{part}}</b>
        <br />
        <h4>
          The following is a list of transaction data lists, detailed process
          product lists
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div v-if="addData" class="col-md-5">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Add Process of  <b style="color:red"> {{part}} </b> </h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Code</label>
                  <input
                    type="hidden"
                    v-model="isidata.idxproduk"
                    autocomplete="off"
                    class="form-control"
                  />
                  <!-- <select
                    @change="viewDetail($event)"
                    v-model="isidata.kodeproses"
                    class="form-control"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="dataproseslist in proseslist"
                      :key="dataproseslist.kode_proses"
                      :value="dataproseslist.kode_proses"
                    >
                      {{ dataproseslist.nama_proses }}
                    </option>
                  </select> -->
                  <select2
                    :data="proseslist"
                    :value="value2"
                    @update="update2($event)"
                  ></select2>
                </div>
                <!-- <div class="form-group">
                  <label for="exampleInputEmail1">Process Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.prosesnm"
                    autocomplete="off"
                    class="form-control"
                  />
                </div> -->
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.catatanp"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <!-- <div class="form-group">
                  <label for="exampleInputEmail1">
                    Select2 Example ({{ value2 }})
                  </label>
                  <select2
                    :model="isidata.kodeproses"
                    :data="proseslist"
                    :value="value2"
                    @update="update2($event)"
                  ></select2>
                </div> -->
              </div>
              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
                <!-- &nbsp;
                <router-link to="/produk">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link> -->
              </div>
            </form>
          </div>
        </div>

        <div v-else-if="isFrmeditData" class="col-md-5">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Product List Data Process Detail</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Code</label>
                  <input
                    type="text"
                    v-model="isidata.idxprodukdproses"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="text"
                    v-model="isidata.idxprodukd"
                    autocomplete="off"
                    class="form-control"
                  />
                  <select
                    @change="viewDetail($event)"
                    v-model="isidata.kodeproses"
                    class="form-control"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="dataproseslist in proseslist"
                      :key="dataproseslist.kode_proses"
                      :value="dataproseslist.kode_proses"
                    >
                      {{ dataproseslist.nama_proses }}
                    </option>
                  </select>
                </div>
                <!-- <div class="form-group">
                  <label for="exampleInputEmail1">Process Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.prosesnm"
                    autocomplete="off"
                    class="form-control"
                  />
                </div> -->
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.catatanp"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <button @click="awaldepan()" class="btn btn-success">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-7">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">List Process of <b style="color:red">{{part}}</b></h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Process Name"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-1">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Process Code</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.kode_proses }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>
                        <!-- <router-link
                          :to="'/edit-produkproses-detail/' + classdata.id"
                        >
                          <button
                            title="Edit product process details"
                            class="btn btn-info"
                          >
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link> -->
                        <!-- <button
                          @click="EditData(classdata.id)"
                          title="Edit product process details"
                          class="btn btn-info"
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        &nbsp; -->
                        <!-- <router-link
                          :to="'/addbom-produkproses-detail/' + classdata.id"
                        >
                          <button
                            title="Add BOM Product Process Detail"
                            class="btn btn-success"
                          >
                            <i class="fa fa-plus"></i> Add BOM Product Process Detail
                          </button>
                        </router-link> &nbsp; -->
                        <router-link :to="'/produk-prosesbom/' + classdata.id">
                          <button
                            @click="setProses(classdata.kode_proses)"
                            title="List of BOM Products Process Details"
                            class="btn btn-success"
                          >
                            <i class="fa fa-list"></i>
                          </button>
                        </router-link>
                        &nbsp; &nbsp;
                        <button
                          title="Delete product process details"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>

            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Process Code</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination
  },
  data() {
    return {
      isidata: {
        idxproduk: "",
        kodeproses: "",
        // prosesnm: "",
        catatanp: "",
        idxprodukdproses: "",
        idxprodukd: ""
      },
      value2: "",
      value3: "",
      part:"",
      product:"",
      isShow: false,
      isExist: true,
      loading: false,
      namesearch: "",
      list_paged: [],
      proseslist: [],
      addData: true,
      isFrmeditData: false,
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      produkno: "",
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
    this.listdataprocess();
    this.isidata.idxproduk = this.$route.params.id;
    this.part=localStorage.bompart;
    this.product=localStorage.bomproduct;
  },
  methods: {
    awaldepan() {
      this.addData = true;
      this.isFrmeditData = false;
      this.isidata.kodeproses = "";
      // this.isidata.prosesnm = "";
      this.isidata.catatanp = "";
      this.fetchData();
    },
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if(this.namesearch == '') {
        this.fetchData();
      } else {
        this.searchdata()
      }
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if(this.namesearch == '') {
        this.fetchData();
      } else {
        this.searchdata()
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    /* viewDetail(event) {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const proseslist = event.target.value;
      const urlAPIget =
        this.$apiurl +
        "daftar_proses/getdaftar_prosesbykode?kode_proses=" +
        proseslist;

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.isidata.prosesnm = resp.data.data.nama_proses;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    }, */
    async saveData() {
      this.loading = true;
      var idxpp = this.isidata.idxproduk;
      var kodepro = this.value2;
      // var namapros = this.isidata.prosesnm;
      var notesp = this.isidata.catatanp;

      if (kodepro == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Process Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notesp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        var kodeuser = sessionStorage.getItem("kodeuser");
        var paramdata = {
          id_produk_d: idxpp,
          kode_proses: kodepro,
          notes: notesp,
          kode_user: kodeuser
        };

        var tokenlogin = sessionStorage.getItem("token");
        // var urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/saveproduk_d_proses";
        var urlAPIsavedata =
          this.$apiurl + "produk_d_proses/saveproduk_d_proses";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              })
              this.value2 = "";
              // this.isidata.prosesnm = "";
              this.isidata.catatanp = "";
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async updateData() {
      this.loading = true;
      var idxproddp = this.isidata.idxprodukdproses;
      var idxprodd = this.isidata.idxprodukd;
      // var kodep = this.isidata.kodeproses;
      var kodep = this.value2;
      // var namaproses = this.isidata.prosesnm;
      var ctnp = this.catatanp;
      if (kodep == "" || idxproddp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Process can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ctnp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          id_produk_d: this.isidata.idxprodukd,
          // kode_proses: this.isidata.kodeproses,
          kode_proses: this.value2,
          notes: this.isidata.catatanp,
          kode_user: kodeuser
        };

        // console.log(paramdatadetail);
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPIUpdateDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/updateproduk_d_proses/" + idx;
        const urlAPIUpdateDatadetail =
          this.$apiurl + "produk_d_proses/updateproduk_d_proses/" + idxprodd;
        axios
          .put(urlAPIUpdateDatadetail, paramdatadetail, { headers: headers })
          .then((respn) => {
            // console.log(respn);
            // console.log(urlAPIUpdateDatadetail);
            // console.log(paramdatadetail);
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
              this.addData = true;
              this.value2 = "";
              // this.isidata.kodeproses = "";
              // this.isidata.prosesnm = "";
              this.isidata.catatanp = "";
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    update2(value) {
      this.value2 = value;
      // this.isidata.prosesnm = value
    },
    listdataprocess() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/daftar_proses/getalldatadaftar_proses?length=" + this.pageSize + "&page=" + this.page;
      const urlAPIget = this.$apiurl + "daftar_proses/getdaftar_proses_list";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.proseslist = resp.data.data;
          this.proseslist.forEach((item) => {
            item.value = item.kode_proses;
            item.label = item.nama_proses;
            //  console.log(JSON.stringify(this.proseslist2))
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    EditData(id) {
      this.isFrmeditData = true;
      this.addData = false;
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl + "produk_d_proses/getproduk_d_prosesbyid?id=" + id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.isidata.idxprodukdproses = resp.data.data.id_produk_d;
          this.isidata.idxprodukd = resp.data.data.id;
          // this.isidata.kodeproses = resp.data.data.kode_proses;
          this.value2 = resp.data.data.kode_proses;
          this.isidata.catatanp = resp.data.data.notes;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/getalldataproduk_d_proses?length="+this.pageSize+"&page="+this.page+"&id_produk_d=" + this.$route.params.id
      const urlApiGETdetail = this.$apiurl + "produk_d_proses/getalldataproduk_d_proses?length=" + this.pageSize + "&page=" + this.page + "&id_produk_d=" +
        this.$route.params.id;
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          // console.log(resp)
          if(resp.data.data.length == 0){
            this.isExist = false
          } else {
            this.isExist = true
            this.list_paged = resp.data.data;
            this.produkno = sessionStorage.getItem("noproduk");
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    searchdata() {
      this.loading = true;
      const paramsst = this.getRequestParams(this.page);
      //const paramsst = this.getRequestParams(this.pagestat);
      const tokenlogin = sessionStorage.getItem("token");
        // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/getalldataproduk_d_proses?length="+this.pageSize+"&cari="+this.namesearch+"&page="+this.page+"&id_produk_d=" + this.$route.params.id
        const urlApiGETdetail =
          this.$apiurl +
          "produk_d_proses/getalldataproduk_d_proses?length=" +
          this.pageSize +
          "&cari=" +
          this.namesearch +
          "&page=" +
          this.page +
          "&id_produk_d=" +
          this.$route.params.id;
        axios
          .get(urlApiGETdetail, {
            paramsst,
            headers: { Authorization: tokenlogin }
          })
          .then((resp) => {
            if(resp.data.data.length == 0) {
              this.isExist = false
            } else {
              this.isExist = true
              this.list_paged = resp.data.data;
              this.count = resp.data.datatotalcount;
            }
            this.loading = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data
            })
          });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/hapusproduk_d_proses/" + id;
          const urlAPIbrgdel =
            this.$apiurl + "produk_d_proses/hapusproduk_d_proses/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              })
            });
        } else {
          this.loading = false;
        }
      });
    },
    setProses(process){
      localStorage.setItem('bomprocess',process);
    }

  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
